import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Countdown from "react-countdown";
// import MintingComponent from "../components/mintingComponent";
import mintPunk from "../images/mintPunk.png";
import "./slashMint.css";
import MintingMaster from "../components/mintingComponents";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // return <MintingComponent />;
    return <MintingMaster />;
  } else {
    // Render a countdown
    return (
      <div className="mintingCountDownParent">
        <div className="mintingDate">
          <div>
            <span>
              28<sup>th</sup>
            </span>
          </div>
          <div className="mintTimes">
            <span> March </span>{" "}
            <span>
              4pm <span className="utc"> UTC</span>
            </span>
          </div>
        </div>

        <div className="mintingCountDown">
          <span>{days}D</span> <span>{hours}H</span> <span>{minutes}M</span> <span>{seconds}S</span>
        </div>
      </div>
    );
  }
};

function SlashMint(props) {
  const dispatch = useDispatch();
  const [millisecondsToRelease, setMillisecondsToRelease] = useState(new Date("March 28, 2024 21:00:00 UTC+05:00"));

  return (
    <div className="mintContainer">
      <div className="mintLeftContainer">
        <div className="mintDetailsParent">
          <h1 className="mintHeading"> VENOM PUNKS</h1>
          {/* <div>
            <span className="mintSub1">First ever NFT Project</span>{" "}
            <span className="mintSub2">on Venom Foundation</span>
            <p className="mintParagraph">
              Lorem ipsum dolor sit amet consectetur. Erat dolor integer ultrices mauris. Etiam non leo id in euismod
              vitae ullamcorper. At neque nunc magna risus et amet suspendisse adipiscing est. Ullamcorper fames a
              sollicitudin neque.
            </p>
          </div> */}
        </div>

        <div>
          <Countdown date={millisecondsToRelease} renderer={renderer} />
        </div>
      </div>

      <div className="mintRightContainer">
        <img src={mintPunk} alt="Punk Image" />
      </div>

      {/* <div className="bodymintingButtons">
        <Countdown date={millisecondsToRelease} renderer={renderer} />
        <br />
      </div>  */}
    </div>
  );
}

export default SlashMint;
