import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./slash.css";
import slashpunk from "../images/all-gif.gif";
import female from "../images/female.png";
import punk10 from "../images/punk10.svg";
import zoombie from "../images/zoombie.png";
import male from "../images/male-1.png";
import alien from "../images/alien-1.png";
import lastPhase from "../images/male-1.png";
import ape from "../images/ape-1.png";
import blackpunk from "../images/blackpunk.svg";
import timelineImage from "../images/maskpunk.svg";
import { PlusOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Collapse, Timeline } from "antd";
import center from "../images/center.png";
import Traits from "./traits";
import { useDispatch, useSelector } from "react-redux";
import { GetUserRole, reSetRole } from "../redux/reducers/user";
import { Modal, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
// import MintingComponent from "../components/mintingComponent";
import Countdown from "react-countdown";

const { Panel } = Collapse;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return (
      <Link to="/officialmint">
        <button className="checkRole" style={{ width: "19em" }}>
          Mint
        </button>
      </Link>
    );
  } else {
    // Render a countdown
    return (
      <div className="countDown2">
        {/* <div className="phase2mint"> Phase-2 : Test Mint Starts In</div> <br /> */}
        <span>{days}D</span> <span>{hours}H</span> <span>{minutes}M</span> <span>{seconds}S</span>
      </div>
    );
  }
};

function Slash(props) {
  const { loading, userRole, success } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [userRoleState, setUserRoleState] = useState(null);
  const [walletAddress, setWalletAddress] = useState("");
  const navigate = useNavigate();
  const [millisecondsToRelease, setMillisecondsToRelease] = useState(new Date("March 28, 2024 21:00:00 UTC+05:00"));

  // const navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/mint");
  // }, [navigate]);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (!modalVisible) {
      setUserRoleState(null);
    }
  }, [modalVisible]);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };
  const onChange = key => {};

  const genExtra = () => (
    <PlusOutlined
      onClick={event => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.Propagation();
      }}
      style={{ color: "#fff" }}
    />
  );

  const handleSubmit = () => {
    // Check if the wallet address is empty or not provided
    if (!walletAddress || walletAddress.trim() === "") {
      setUserRoleState("Please enter your wallet address.");
      return; // Prevent further execution if there's an error
    }

    // Check if the wallet address is valid (in this case, we check if its length is less than 10)
    if (walletAddress.length < 10) {
      setUserRoleState("You have entered an invalid wallet address.");
      return; // Prevent further execution if there's an error
    }

    // If the wallet address is valid, dispatch the GetUserRole action
    dispatch(GetUserRole(walletAddress));
  };

  // Listen for changes in userRole and success
  useEffect(() => {
    if (success) {
      setUserRoleState(userRole);
    }
  }, [userRole, success]);

  console.log(userRoleState, "userRole");
  return (
    <>
      <div className="slashHeader">
        <div className="slash-body1-parrent">
          <div className="slash-body">
            <div className="slashPara">
              <div className="venom-punks-large">VENOM PUNKS</div>
              <p>
                First-Ever NFT Collection on <span className="slashVenom">Venom Foundation</span>
                <span className="slashBlockchain"> BlockChain</span>
              </p>
              <p>
                3000 Nfts
                <span className="slashBlockchain"> Soldout</span>
              </p>
              <div>
                {/* <Countdown date={millisecondsToRelease} renderer={renderer} /> */}
                {/* <span className="slashBlockchain"> Mint Comming Soon</span> */}
                <div className="home-buttons">
                  {/* <button
                    className="slashMint1"
                    onClick={() => {
                      navigate("/submit");
                    }}
                  >
                    Submit Wallet
                  </button> */}
                  {/* <div> */}
                  {/* <button onClick={showModal} className="slashMint2">
                    Wallet Checker
                  </button> */}
                  {/* </div> */}
                </div>
              </div>
              {/* <button
             className="slashMint"
             onClick={() => {
               navigate("/mint");
             }}
           >
             Mint
           </button> */}
              {/* <button
                className="slashMint"
                onClick={() => {
                  navigate("/submit");
                }}
              >
                Submit Wallet
              </button> */}

              {/* <div>
                <button onClick={showModal} className="checkRole">
                  Verify Submission
                </button>
              </div> */}
              <Modal
                title={null}
                visible={modalVisible}
                footer={null}
                closeIcon={null}
                closable={false}
                bodyStyle={{ padding: 0 }} // Remove padding
                className="modalStyle"
              >
                {/* Your modal content goes here */}
                <CloseOutlined
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    fontSize: 20,
                    cursor: "pointer",
                    color: "white",
                  }}
                  onClick={() => {
                    dispatch(reSetRole);
                    setUserRoleState("");
                    handleCancel();
                    setWalletAddress("");
                  }}
                />

                <div className="verifyRole">
                  <p>Wallet Checker</p>
                  <div className="inputAndBtn">
                    <input
                      placeholder="Enter your wallet address"
                      value={walletAddress}
                      onChange={e => setWalletAddress(e.target.value)}
                    />
                    <button onClick={handleSubmit}>{loading ? "Loading..." : "Submit"}</button>
                    <br />
                  </div>
                </div>

                {userRoleState === "" && (
                  <p style={{ color: "Red" }} className="responce">
                    {" "}
                    You are not eligible.
                  </p>
                )}
                {userRoleState && userRoleState === "OG" && (
                  <p style={{ color: "#b545b3" }} className="responce">
                    {" "}
                    You are eligible for the OG mint.
                  </p>
                )}
                {userRoleState && userRoleState === "WL" && (
                  <p style={{ color: "#ad1c41" }} className="responce">
                    {" "}
                    You are eligible for the WL mint.
                  </p>
                )}
                {userRoleState && userRoleState !== "WL" && userRoleState !== "OG" && userRoleState !== "" && (
                  <p style={{ color: "Red" }} className="responce">
                    {userRoleState}
                  </p>
                )}
              </Modal>

              {/* <div className="bodymintingButtons"> */}
              {/* <Countdown date={millisecondsToRelease} renderer={renderer} /> */}
              <br />
              {/* </div> */}
            </div>

            <div>
              <img src={slashpunk} className="gif" />
            </div>
          </div>
        </div>

        <div className="slashAboutUsParrent">
          <div className="slashAboutUSBody">
            <div>
              <p>
                {" "}
                <span className="aboutHeadingThe"> The Punks Sprit Sparked A Journey of</span> <br />
                <span className="aboutHeadingLimit">LIMITLESS POSSIBILITIES</span>{" "}
              </p>
              {/* <span className="aboutSpan">ABOUT</span> <span className="slashUS">ARTWORK</span> <br /> */}
              <p className="aboutPara">
                Venom Punks, the first-ever NFT project on Venom Foundation, is at the forefront of innovation, setting
                the benchmark for others to follow. With a limited collectibles of 10,000, these Punks will live forever
                on the{" "}
                <a
                  href="https://venom.foundation/"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#6773e9" }}
                >
                  Venom Foundation Blockchain.
                </a>
                <br />
                <br /> Venom Punks aims to become the leading NFT collection on the Venom Foundation. As the first and
                only project of its kind, Venom Punks will hold a unique position in the ecosystem. Our goal is to make
                Venom Punks one of the most sought-after collections, offering exciting utilities that will blow your
                mind! Get ready for an unforgettable experience with Venom Punks!
              </p>
            </div>
            <div className="learnMore">{/* <button>Learn More</button> */}</div>
          </div>
          <div className="aboutImages">
            <div className="aboutImages-d1">
              <img src={female} alt="aboutImages" />
            </div>
            <div className="aboutImages-d2">
              <img src={zoombie} alt="aboutImages" className="aboutImg2" />
            </div>
            <div className="aboutImages-d3">
              <img src={alien} alt="aboutImages" className="aboutimg3" />
            </div>
            <div className="aboutImages-d4">
              <img src={male} alt="aboutImages" className="aboutimg4" />
            </div>
            <div className="aboutImages-d5">
              <img src={ape} alt="aboutImages" className="aboutimg4" />
            </div>
            <div className="aboutImages-d5 large-only">
              <img src={blackpunk} alt="aboutImages" className="aboutimg4" />
            </div>
          </div>
        </div>
        <div>
          <div className="animationContainer">
            <img src={center} alt="Center Image" className="animationCenterImg" />
            {/* <div className="animationsitem img1">
           <img src={female} alt="aboutImages" />
         </div>
         <div className="animationsitem img2">
           <img src={zoombie} alt="aboutImages" className="aboutImg2" />
         </div>
         <div className="animationsitem img3">
           <img src={alien} alt="aboutImages" className="aboutimg3" />
         </div>
         <div className="animationsitem img4">
           <img src={male} alt="aboutImages" className="aboutimg4" />
         </div>
         <div className="animationsitem img5">
           <img src={ape} alt="aboutImages" className="aboutimg4" />
         </div>
         <div className="animationsitem img6">
           <img src={blackpunk} alt="aboutImages" className="aboutimg4" />
         </div> */}
          </div>
        </div>
        <div className="roadmap-web">
          <div className="theStoryHeading" style={{ marginBottom: "2em" }}>
            <span className="storyThe">Road</span>
            <span>Map</span>
          </div>
        </div>
        <Timeline
          className="custom-timeline"
          mode="alternate"
          items={[
            {
              children: (
                <div className="" style={{ padding: "1em" }}>
                  <span className="text-white">Phase 1</span>
                  <ul>
                    <li
                      style={{
                        textDecoration: "line-through",
                        fontSize: "11px",
                        textDecorationColor: "#6773e9",
                        textDecorationThickness: "2px",
                      }}
                    >
                      Initiate Venom Punks{" "}
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                        fontSize: "11px",
                        textDecorationColor: "#6773e9",
                        textDecorationThickness: "2px",
                      }}
                    >
                      {" "}
                      Establish Discord and Twitter{" "}
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                        fontSize: "11px",
                        textDecorationColor: "#6773e9",
                        textDecorationThickness: "2px",
                      }}
                    >
                      {" "}
                      Secure venompunks.com and venomsea.com domains{" "}
                    </li>
                  </ul>
                </div>
              ),
              color: "#26b574",
              dot: <img src={punk10} alt="alt" className="timeline-img" style={{ margin: "5px" }} />,
            },
            {
              children: (
                <div className="" style={{ padding: "1em" }}>
                  <span className="text-white">Phase 2</span>
                  <ul>
                    <li
                      style={{
                        textDecoration: "line-through",
                        fontSize: "11px",
                        textDecorationColor: "#6773e9",
                        textDecorationThickness: "2px",
                      }}
                    >
                      Open Discord and Twitter gates for the Punks
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                        fontSize: "11px",
                        textDecorationColor: "#6773e9",
                        textDecorationThickness: "2px",
                      }}
                    >
                      Deploy Smart Contract for seamless testing
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                        fontSize: "11px",
                        textDecorationColor: "#6773e9",
                        textDecorationThickness: "2px",
                      }}
                    >
                      Tease stunning artwork glimpses{" "}
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                        fontSize: "11px",
                        textDecorationColor: "#6773e9",
                        textDecorationThickness: "2px",
                      }}
                    >
                      Release the captivating Whitepaper and Roadmap{" "}
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                        fontSize: "11px",
                        textDecorationColor: "#6773e9",
                        textDecorationThickness: "2px",
                      }}
                    >
                      Launch the official venompunks.com website
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                        fontSize: "11px",
                        textDecorationColor: "#6773e9",
                        textDecorationThickness: "2px",
                      }}
                    >
                      Test minting to familiarize with Venom NFT minting
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                        fontSize: "11px",
                        textDecorationColor: "#6773e9",
                        textDecorationThickness: "2px",
                      }}
                    >
                      {" "}
                      Unleash strategic Marketing initiatives{" "}
                    </li>
                  </ul>
                </div>
              ),
              dot: <img src={timelineImage} alt="alt" className="timeline-img" />,
            },
            {
              dot: <img src={alien} alt="alt" className="timeline-img" />,
              children: (
                <div className="" style={{ padding: "1em" }}>
                  <span className="text-white">Phase 3</span>
                  <ul>
                    <li
                      style={{
                        textDecoration: "line-through",
                        fontSize: "11px",
                        textDecorationColor: "#6773e9",
                        textDecorationThickness: "2px",
                      }}
                    >
                      {" "}
                      Unveil the revolutionary Venomsea Marketplace{" "}
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              dot: <img src={ape} alt="alt" className="timeline-img" id="tobeComplete" />,
              children: (
                <div className="" style={{ padding: "1em" }}>
                  <span className="text-white">Phase 4</span>
                  <ul>
                    <li style={{ fontSize: "11px" }}> Embark on the Official Minting process </li>
                    <li style={{ fontSize: "11px" }}>Introduce exciting Roles and channels for community holders</li>
                    <li style={{ fontSize: "11px" }}>Reveal mesmerizing Punks artwork collection</li>
                  </ul>
                </div>
              ),
            },
            {
              dot: <img src={zoombie} alt="alt" className="timeline-img" />,
              children: (
                <div className="" style={{ padding: "1em" }}>
                  <span className="text-white">Phase 5</span>
                  <ul>
                    <li style={{ fontSize: "11px" }}> Begin Venom Punks stake for passive income </li>
                    <li style={{ fontSize: "11px" }}>Establish a powerful DAO for collective decision-making</li>
                    <li style={{ fontSize: "11px" }}>Experimental TIP404 standard for TVM-compatible blockchains</li>
                  </ul>
                </div>
              ),
            },
            {
              dot: <img src={lastPhase} alt="alt" className="timeline-img" />,
              children: (
                <div className="" style={{ padding: "1em" }}>
                  <span className="text-white">Phase 6</span>
                  <ul>
                    <li style={{ fontSize: "11px" }}>{"FlashPunks (MEV)"} </li>
                  </ul>
                </div>
              ),
            },
          ]}
        />

        <div className="utilittiesContainer">
          <p className="utlitttiesHeading">
            Utilities & <span className="utliteisProduct">Products</span>
          </p>
          <div className="utilitiesParent">
            {/* box 1 */}
            <div className="utilitesbody">
              <div>
                <a href="https://www.venomsea.io" target="_blank" id="utility-title">
                  Venomsea.io
                </a>
              </div>
              <div className="utility-subtitle">NFT Marketplace</div>
              <br />
              <div className="utility-desc">
                Introducing VenomSea, an NFT marketplace on Venom Foundation, focused on their growth and success. Our
                unique revenue-sharing model sets us apart, allocating 50% of generated revenues directly to Venom Punks
                holders as a loyalty reward. The remaining portion is strategically used for marketing initiatives and
                operational expenses, ensuring ongoing marketplace expansion and enhancement.
              </div>
            </div>
            <div className="utilitesbody">
              <div>
                <a id="utility-title">Flash Punks</a>
              </div>
              <div className="utility-subtitle">{"MEV"}</div>
              <br />
              <div className="utility-desc">
                {
                  "MEV represents the maximum potential profit attainable by blockchain miners, validators, or node operators through various activities such as arbitrage and participation in validator nodes. FlashPunks https://www.flashpunks.xyz/ is committed to engaging in responsible and transparent MEV practices within state-full blockchains. Our native platform token, $FlashPunks (TIP3/ERC20), will serve as the cornerstone of this ecosystem, enabling participation from all members."
                }
              </div>
            </div>
            <div className="utilitesbody">
              <div>
                <a target="_blank" id="utility-title">
                  Experimental
                </a>
              </div>
              <div className="utility-subtitle">TIP404 Standard</div>
              <br />
              <div className="utility-desc">
                Our forthcoming initiative involves the development of TIP404 smart contracts aimed at addressing NFT
                liquidity concerns. These contracts will represent an experimental standard intended for adoption across
                all TVM-compatible blockchains, including but not limited to Everscale, Venom, Gosh, and TON. We are
                dedicated to elucidating the advantages accruing to our community members and outlining the practical
                utilization of this innovative solution.
                <br />
                <br />
              </div>
            </div>
            {/* <div>.</div> */}
            {/* box 2 */}
            <div className="utilitesbody">
              <div>
                <a id="utility-title">Venom Punks</a>
              </div>
              <div className="utility-subtitle">IP Rights</div>
              <br />
              <div className="utility-desc">
                As originators of Venom Punks, we grant our holders complete intellectual property rights, offering
                absolute ownership of these digital masterpieces. Enjoy the freedom to use Venom Punks for personal,
                commercial, and non-profit purposes, unleashing boundless creativity and entrepreneurial endeavors.
                Embrace the potential of these remarkable artworks and unlock a world of limitless possibilities, where
                ownership extends beyond possession.
                <br />
                <br />
              </div>
            </div>
            <div className="utilitesbody">
              <div>
                <a id="utility-title"> Punks</a>
              </div>
              <div className="utility-subtitle">Staking</div>
              <br />
              <div className="utility-desc">
                Our team of developers is poised to embark on an extensive venture encompassing the creation of numerous
                dApps in the foreseeable future. Among these dApps are VenomSea, TIP404, and various other innovative
                products. Notably, holders of Venom Punks who choose to stake their assets will enjoy direct rewards
                stemming from the activities of these platforms. We are committed to providing a comprehensive overview
                of the staking mechanism, detailing its intricacies and benefits to our stakeholders.
              </div>
            </div>

            {/* <div>.</div> */}
            {/* box 3 */}
            <div className="utilitesbody">
              <div>
                <a id="utility-title">VPunksDAO</a>
              </div>
              <div className="utility-subtitle">DAO</div>
              <br />
              <div className="utility-desc">
                Introducing the Venom Punks DAO, a collaborative framework for Venom Punks holders. This DAO brings
                together the Venom Punks team and the community to shape the project's future. Stakeholders actively
                contribute ideas and make collective decisions, empowering each member to impact the project's
                trajectory. This democratic approach fosters shared vision and sustainable growth.
              </div>
            </div>
            <div className="utilitesbody">
              <div>
                <a id="utility-title">Venom</a>
              </div>
              <div className="utility-subtitle">Identity & gateway</div>
              <br />
              <div className="utility-desc">
                Embrace the potential of web3 and blockchain technology with Venom Punks, your gateway to the
                extraordinary Venom Foundation Blockchain. Join us in an exceptional space filled with limitless
                opportunities to explore uncharted territories, unlock new horizons, and unleash your creative
                potential. Prepare for a transformative journey where innovation, connectivity, and endless
                possibilities converge at every turn.
                <br />
                <br />
              </div>
            </div>
            {/* <div>.</div> */}
            {/* box 4 */}

            {/* <div>.</div> */}
            {/* box 5 */}

            {/* <div>.</div> */}
            {/* box 6 */}
            <div className="utilitesbody">
              <div>
                <a id="utility-title">Whitelist</a>
              </div>
              <div className="utility-subtitle">Spots</div>
              <br />
              <div className="utility-desc">
                We actively seek opportunities for Venom Punks holders in upcoming NFT projects. Through careful
                evaluation, we aim to secure exclusive whitelist spots. Stay tuned as we strategically position our
                community for curated opportunities, driving the growth and prosperity of Venom Punks.
              </div>
            </div>

            {/* from here */}

            <div className="utilitesbody">
              <div>
                <a id="utility-title">Opportunities</a>
              </div>
              <div className="utility-subtitle">For All Venom Punks Future Products</div>
              <br />
              <div className="utility-desc">
                Join us on an exciting journey as we unveil novel products and opportunities that will elevate Venom
                Punks to new heights. As esteemed holders, you'll have exclusive access to upcoming offerings, driven by
                the indomitable spirit of Venom Punks. Prepare to immerse yourself in a realm of limitless innovation
                and progressive ventures, where your participation and support shape an extraordinary evolution,
                ensuring the enduring legacy of Venom Punks.
                <br />
                <br />
              </div>
            </div>
            <div className="utilitesbody">
              <div>
                <a id="utility-title"> Punks</a>
              </div>
              <div className="utility-subtitle">Staking</div>
              <br />
              <div className="utility-desc">
                Our team of developers is poised to embark on an extensive venture encompassing the creation of numerous
                dApps in the foreseeable future. Among these dApps are VenomSea, TIP404, and various other innovative
                products. Notably, holders of Venom Punks who choose to stake their assets will enjoy direct rewards
                stemming from the activities of these platforms. We are committed to providing a comprehensive overview
                of the staking mechanism, detailing its intricacies and benefits to our stakeholders.
              </div>
            </div>
            <div className="utilitesbody">
              <div>
                <a id="utility-title">Holders</a>
              </div>
              <div className="utility-subtitle">Roles</div>
              <br />
              <div className="utility-desc">
                To foster a close-knit community and strengthen the bond among holders and whales, we are pleased to
                introduce exclusive roles and channels. These dedicated channels will serve as a platform for enhanced
                communication, fostering robust support and camaraderie within the Venom Punks community.
              </div>
            </div>
            <div className="utilitesbody">
              <div>
                <a target="_blank" id="utility-title">
                  Experimental
                </a>
              </div>
              <div className="utility-subtitle">TIP404 Standard</div>
              <br />
              <div className="utility-desc">
                Our forthcoming initiative involves the development of TIP404 smart contracts aimed at addressing NFT
                liquidity concerns. These contracts will represent an experimental standard intended for adoption across
                all TVM-compatible blockchains, including but not limited to Everscale, Venom, Gosh, and TON. We are
                dedicated to elucidating the advantages accruing to our community members and outlining the practical
                utilization of this innovative solution.
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
        <div style={{ color: "white" }}>
          <Traits />
        </div>
        <div className="teamParent">
          <div className="teamParent-d1">Team</div>
          <div className="teamParent-d2">
            {[
              {
                name: "Zeeshanpaalo",
                title: "Founder | Software Architect",
                description:
                  "A Software Architect, Open source contributor, and Web3 Adoption Advocate with a strong passion for innovation. I have extensive experience in building robust systems and architecting scalable frameworks. With a track record of supporting Web3 startups, I combine technical expertise with entrepreneurial acumen. My commitment to open-source collaboration and belief in the transformative potential of Web3 technologies drive my mission to shape a decentralized future.",
              },
              {
                name: "Pitiki",
                title: "Founder | Artist",
                description:
                  "I am a versatile artist and design enthusiast with a strong background in Product Design, UI/UX Design, and Graphic Design. With a passion for visually engaging experiences, I am dedicated to enhancing Web3 adoption through seamless user experiences. As an accomplished NFT artist, I combine my artistic flair with cutting-edge technology to create captivating digital artwork. With a focus on user-centric design, I am determined to push boundaries and shape the future of the NFT ecosystem.",
              },
              {
                name: "NftyJR | Pikachu",
                title: "Community Manager",
                description:
                  "I am a business administration graduate who specializes in supply chain management. I've been working in the NFT field for over two years and have worked on over 20 projects, sometimes two or three at the same time. I resigned from my job to devote all of my time and energy to this project because I believe Web 3.0 is the future.",
              },

              {
                name: "NikJr",
                title: "Senior Blockchain Engineer",
                description:
                  "A Software Developer with three years of expertise in creating web2 and web3 applications. I am now developing ERP solutions and giving my expertise to Venompunks. Aside from development, I've discovered a passion for NFTs and community building, therefore I like being involved in the community.",
              },
              {
                name: "Broung",
                title: "Senior Software Engineer",
                description:
                  "I'm a Senior Software Engineer with over four years of experience in programming and design. I love creating user interfaces that look perfect down to the last pixel. As a Full Stack Developer, I specialize in Blockchain, web3.0,Ethereum, Solana, and Venom Blockchain technologies. I'm passionate about pushing the boundaries of innovation in software development.",
              },

              {
                name: "J D",
                title: "Community Moderator",
                description:
                  "I'm a 25-year-old professional with a Bachelor of Business degree and a strong interest in the quickly expanding world of cryptocurrencies. I've spent the last two years as a Core Team Member on numerous NFT initiatives, honing my talents in social media management, community interaction, and project organization.",
              },
            ].map(teamMember => {
              return (
                <div className="teamParent-card">
                  <div className="teamParent-name">{teamMember.name}</div>
                  <div className="teamParent-title">{teamMember.title}</div>
                  <div className="teamParent-desc">{teamMember.description}</div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="faqsParent">
          <div>
            <div className="faqs-d1">Frequently Asked</div>
            <div className="faqs-d2">Question</div>
          </div>
        </div>
        <div style={{ marginTop: "20px", marginBottom: "30px" }}>
          <div className="faq-questions-web">
            <Collapse defaultActiveKey={["0"]} onChange={onChange}>
              <Panel header="What is Venom Punks?" key="1" extra={genExtra()} showArrow={true}>
                <span>
                  Venom Punks is the first-ever NFT project on the Venom Foundation. It is a collection of 10,000
                  pixelated punks that aims to become the leading NFT collection on the Venom Foundation platform. Venom
                  Punks building a robust ecosystem that offers Venom Punks holders a range of long-term utilities.
                </span>
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={["0"]} onChange={onChange} expandIconPosition={"end"}>
              <Panel
                header="What are mint phases and when do they take place?"
                key="1"
                extra={genExtra()}
                showArrow={false}
              >
                <div>
                  There will be three mint stages, OG and WL will on 28th March and Public will be on 29th March:
                  <li color="white">
                    <ul>OG Sale</ul>
                    <ul>WL Sale</ul>
                    <ul>Public Sale</ul>
                  </li>
                </div>
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={["0"]} onChange={onChange} expandIconPosition={"end"}>
              <Panel header="What are the mint prices?" key="1" extra={genExtra()} showArrow={false}>
                <div>
                  <li>
                    <ul>OG: $30</ul>
                    <ul>WL: $40</ul>
                    <ul>Public: $60</ul>
                  </li>
                </div>
              </Panel>
            </Collapse>
          </div>

          <div className="faq-questions-web">
            <Collapse defaultActiveKey={["0"]} onChange={onChange}>
              <Panel
                header="What is the max number of tokens that can be minted per transaction?"
                key="1"
                extra={genExtra()}
                showArrow={false}
              >
                <div>
                  <li>
                    <ul>OG: Max 10 mint per wallet</ul>
                    <ul>WL: Max 5 mint per wallet</ul>
                    <ul>Public: Max 3 mint per transaction</ul>
                  </li>
                </div>
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={["0"]} onChange={onChange} expandIconPosition={"end"}>
              <Panel
                header="How much is the entire supply and how will it be distributed?"
                key="1"
                extra={genExtra()}
                showArrow={false}
              >
                <div>
                  The entire supply is 10,000, and the distribution will be:
                  <li>
                    <ul>OG: 1500</ul>
                    <ul>WL: 2500</ul>
                    <ul>Public: 5000</ul>
                    <ul>Team: 1000</ul>
                  </li>
                </div>
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={["0"]} onChange={onChange} expandIconPosition={"end"}>
              <Panel header="What is the royalty %?" key="1" extra={genExtra()} showArrow={false}>
                <div>5%</div>
              </Panel>
            </Collapse>
          </div>
          <div className="faq-questions-web">
            <Collapse defaultActiveKey={["0"]} onChange={onChange}>
              <Panel header="What are the official links?" key="1" extra={genExtra()} showArrow={false}>
                <div>
                  <a href="https://linktr.ee/venompunks" target="_blank">
                    https://linktr.ee/venompunks
                  </a>
                </div>
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={["0"]} onChange={onChange} expandIconPosition={"end"}>
              <Panel
                header="Venom Punks is based on which blockchain, and what wallet is required for the mint?"
                key="1"
                extra={genExtra()}
                showArrow={false}
              >
                <div>
                  We are based on the Venom Foundation blockchain, and here is the official URL to download the Venom
                  wallet:
                  <br />
                  <a href="https://venomwallet.com/" target="_blank">
                    https://venomwallet.com/
                  </a>
                </div>
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={["0"]} onChange={onChange} expandIconPosition={"end"}>
              <Panel header="Where can I mint?" key="1" extra={genExtra()} showArrow={false}>
                <div>
                  {/* Here is the official mint link: <br />
                  <a href="https://venompunks.com/mint" target="_blank">
                    https://venompunks.com/mint
                  </a> */}
                  TBA
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="faq-questions-mob">
            <Collapse defaultActiveKey={["0"]} onChange={onChange}>
              <Panel header="What is Venom Punks?" key="1" extra={genExtra()} showArrow={false}>
                <div>
                  Venom Punks is the first-ever NFT project on the Venom Foundation. It is a collection of 10,000
                  pixelated punks that aims to become the leading NFT collection on the Venom Foundation platform. Venom
                  Punks building a robust ecosystem that offers Venom Punks holders a range of long-term utilities.
                </div>
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={["0"]} onChange={onChange} expandIconPosition={"end"}>
              <Panel
                header="What are mint phases and when do they take place?"
                key="1"
                extra={genExtra()}
                showArrow={false}
              >
                <div>
                  There will be three mint stages, OG and WL will on 28th March and Public will be on 29th March:
                  <li color="white">
                    <ul>OG Sale</ul>
                    <ul>WL Sale</ul>
                    <ul>Public Sale</ul>
                  </li>
                </div>
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={["0"]} onChange={onChange} expandIconPosition={"end"}>
              <Panel header="What are the mint prices?" key="1" extra={genExtra()} showArrow={false}>
                <div>
                  <li>
                    <ul>OG: TBA</ul>
                    <ul>WL: TBA</ul>
                    <ul>Public: TBA</ul>
                  </li>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="faq-questions-mob">
            <Collapse defaultActiveKey={["0"]} onChange={onChange}>
              <Panel
                header="What is the max number of tokens that can be minted per transaction?"
                key="1"
                extra={genExtra()}
                showArrow={false}
              >
                <div>
                  <li>
                    <ul>OG: Max 10 mint per wallet</ul>
                    <ul>WL: Max 5 mint per wallet</ul>
                    <ul>Public: Max 3 mint per transaction</ul>
                  </li>
                </div>
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={["0"]} onChange={onChange} expandIconPosition={"end"}>
              <Panel
                header="How much is the entire supply and how will it be distributed?"
                key="1"
                extra={genExtra()}
                showArrow={false}
              >
                <div>
                  The entire supply is 10,000, and the distribution will be:
                  <li>
                    <ul>OG: 1500</ul>
                    <ul>WL: 2500</ul>
                    <ul>Public: 5000</ul>
                    <ul>Team: 1000</ul>
                  </li>
                </div>
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={["0"]} onChange={onChange} expandIconPosition={"end"}>
              <Panel header="What is the royalty %?" key="1" extra={genExtra()} showArrow={false}>
                <div>5%</div>
              </Panel>
            </Collapse>
          </div>
          <div className="faq-questions-mob">
            <Collapse defaultActiveKey={["0"]} onChange={onChange}>
              <Panel header="What are the official links?" key="1" extra={genExtra()} showArrow={false}>
                <div>
                  <a href="https://linktr.ee/venompunks" target="_blank">
                    https://linktr.ee/venompunks
                  </a>
                </div>
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={["0"]} onChange={onChange} expandIconPosition={"end"}>
              <Panel
                header="Venom Punks is based on which blockchain, and what wallet is required for the mint?"
                key="1"
                extra={genExtra()}
                showArrow={false}
              >
                <div>
                  We are based on the Venom Foundation blockchain, and here is the official URL to download the Venom
                  wallet:{" "}
                  <a href="https://venomwallet.com/" target="_blank">
                    https://venomwallet.com/
                  </a>
                </div>
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={["0"]} onChange={onChange} expandIconPosition={"end"}>
              <Panel header="Where can I mint?" key="1" extra={genExtra()} showArrow={false}>
                <div>
                  Here is the official mint link:{" "}
                  <a href="https://venompunks.com/mint" target="_blank">
                    https://venompunks.com/mint
                  </a>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
}

export default Slash;
