import React, { useState, useEffect } from "react";
import axios from "axios";
import { Address, ProviderRpcClient, TvmException } from "everscale-inpage-provider";

import verifyIcon from "../images/verify.png";
import "./walletSubmission.css";
import { useSelector, useDispatch } from "react-redux";
import { SubmitWallet, submitWalletRed } from "../redux/reducers/user";
import { ToastContainer, toast } from "react-toastify";

// const rolesMap = {
//   "1065620555677581434": { name: "V", priority: 3 },
//   "1065621056234213407": { name: "WL", priority: 2 },
//   "1102214688978255963": { name: "OG", priority: 1 },
// };
// Helper function for mapping rolesArray to the greatest role.
function getRoleString(rolesArray) {
  if (rolesArray.find(element => element === "1102214688978255963")) {
    return "OG";
  } else if (rolesArray.find(element => element === "1065621056234213407")) {
    return "WL";
  } else {
    toast.error(`You can't submit wallet! you don't have any roles`, {
      position: "bottom-left",
    });
    return null;
  }
}

function WalletSubmission(props) {
  const dispatch = useDispatch();
  const { venomInstance, venomProvider, standaloneProvider, walletError } = useSelector(state => state.venom);
  const { address, errorMsg, discordRole, discordUserName } = useSelector(state => state.user);

  const [discordVerify, setDiscordVerify] = useState(false);
  const [discordInfo, setDiscordInfo] = useState({});

  console.log(discordInfo, "discordInfo");

  // use useEffect hook to check if we are being redirected to this page after verifying from discord
  useEffect(() => {
    const params = new URLSearchParams(window.location.hash.substr(1));
    const accessToken = params.get("access_token");
    console.log(accessToken);
    if (accessToken) {
      console.log("getting roles");
      // we are being redirected so, handle discord.
      // get roles information for connected user/
      handleGetRoles(accessToken).then(guilds => {
        if (!guilds) {
          // user is not in our server.
          return;
        }
        console.log(guilds,"asdf");
        // set setDiscordInfo
        // dispatch(
        //   submitWalletRed({
        //     discordUserName: guilds.user.username,
        //     discordRole: getRoleString(guilds.roles),
        //   }),
        // );
        setDiscordInfo({
          username: guilds.user.username,
          role: getRoleString(guilds.roles),
        });

        setDiscordVerify(true);
      });
    }
  }, []);

  // use useEffect hook to check if we are being redirected to this page after verifying from discord
  useEffect(() => {
    if (discordRole && discordRole?.length>0) {
      setDiscordVerify(true);
    }
  }, [discordRole]);

  const submitWallet = async () => {
    console.log("submitting wallet");
    console.log(new Address(address));
    const providerState = await venomProvider?.getProviderState?.();
    console.log(providerState?.permissions);
    const publicKey = providerState?.permissions.accountInteraction?.publicKey;
    console.log(publicKey);
    try {
      const base64 = btoa(
        JSON.stringify({
          walletAddress: address,
          discordUserName: discordInfo.username,
          discordRole: discordInfo.role,
        }),
      );
      console.log(base64);
      const sign = await venomProvider.signDataRaw({ data: base64, publicKey, withSignatureId: false });
      console.log(sign);
      dispatch(SubmitWallet({ publicKey, base64, signature: sign.signature }));
    } catch (errr) {
      console.log(errr);
    }
    // venomProvider.sign('ddd');
    // console.log(standaloneProvider)
  };
  //https://venompunks.com
  //http://127.0.0.1:3001
  const handleDiscordConnect = () => {
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=1161620227444965387&redirect_uri=https://venompunks.com/submit&response_type=token&scope=identify%20guilds%20guilds.members.read`;
    // window.location.href = `https://discord.com/api/oauth2/authorize?client_id=1161620227444965387&redirect_uri=http://127.0.0.1:3001/submit&response_type=token&scope=identify%20guilds%20guilds.members.read`;
  };

  const handleGetRoles = async accessToken => {
    try {
      const response = await axios.get("https://discord.com/api/v10/users/@me/guilds/1059909343300046969/member", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const guilds = response.data;
      return guilds;
    } catch (error) {
      console.error("Error fetching guilds:", error);
      return null;
    }
  };
  const connectWllet = async () => {
    if (!venomInstance) return;
    await venomInstance.connect();
  };

  return (
    <div className="walletSubmissionContainer">
      <div className="walletSubmissionParent">
        <div className="stepOneContainer">
          <h3>Step 01 Wallet Connection</h3>
          <p>
            We kindly request you to establish a connection with your wallet to provide us with your wallet address.
          </p>
          {!address && <button onClick={connectWllet}>Connect Wallet</button>}
          {address && (
            <div className="verifyStepOneContainer">
              <img src={verifyIcon} alt="verify" />
              <p>{`${address.substring(0, 5)}....${address.substring(address.length - 4, address.length)}`}</p>
            </div>
          )}
        </div>
        <hr />
        <div className={`${!address ? "stepTwoDisconnectContainer" : "stepTwoContainer"}`}>
          <h3>Step 02 Discord Integration</h3>
          <p>
            Please initiate the connection of your Discord account to ascertain your role, whether it is OG or Punklist.
          </p>
          {discordVerify ? (
            <div className="verifyStepTwoContainer">
              <img src={verifyIcon} alt="verify" />
              <p>Congratulations! successfully connected to discord </p>
            </div>
          ) : (
            <button onClick={handleDiscordConnect} disabled={!address}>
              Verify Discord
            </button>
          )}
        </div>
        <hr />
        <div className={`${!address || !discordInfo.role ? " stepThreeDisconnectContainer" : "stepThreeContainer"}`}>
          <h3>Step 03 Wallet Submission</h3>
          <p>
            To complete the final submission, we kindly request you to log in with your wallet. Please be informed that
            this process does not entail any transaction execution.
          </p>
          {discordRole && discordRole.length === 0 && discordVerify && !discordInfo.role && <p>You don't have any roles</p>}
          {discordRole && discordRole.length>0 ? (
            <div className="verifyStepTwoContainer">
              <img src={verifyIcon} alt="verify" />
              <p>You have successfully submitted your wallet </p>
              <p>{`Discord user: ${discordUserName}`}</p>
              <p>{`Discord role: ${discordRole}`}</p>
            </div>
          ) : (
            <div>
              {discordVerify && (
                <>
                  <p>{`Discord user: ${discordInfo.username}`}</p>
                  <p>{`Discord role: ${discordInfo.role}`}</p>
                </>
              )}
              <button onClick={submitWallet}>Submit Wallet</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WalletSubmission;
