import React from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import discordicon from "../images/discord-icon.png";
import twittericon from "../images/twitter.png";
import "./footer.css";
function Footer(props) {
  let location = useLocation();
  return (
    <div className={classNames("footerContainer", { displayNone: location.pathname === "/mint" })}>
      <div className="footericons">
        <a href="https://twitter.com/venom_punks" target="_blank" rel="noreferrer">
          <img src={twittericon} alt="twitter" />
        </a>
        <a href="https://discord.gg/venompunks" target="_blank">
          <img src={discordicon} alt="dicord" />
        </a>
      </div>
      <hr className="line" />
      <div className="copyRight">
        <p>
          copyright © 2024 &nbsp;
          <a
            href="https://venompunks.com"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "white" }}
          >
            VenomPunks
          </a>
        </p>
        <p>
          Email Us: &nbsp;
          <a href="mailto:askvenompunks@gmail.com" style={{ textDecoration: "none", color: "white" }}>
            VenomPunks
          </a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
