import axios from "axios";

export const BASE_DOMAIN = (() => {
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
    return "http://localhost:3001";
  else {
    //todo main url will be returned
    return "https://venompunks.com";
  }
})();

export const BASE_URL = BASE_DOMAIN;
export const API = axios.create({
  baseURL: BASE_URL,
  timeout: 240000,
  httpAgent: true,
});
