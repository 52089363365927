import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { initVenomConnect } from "../venom-wrapper";
import { GetUser, login, logout } from "../redux/reducers/user";
import { setVenomInstance, setVenomProvider, setStandaloneProvider, setWalletError } from "../redux/reducers/venom";
import HeaderIcon from "../images/header-icon.png";
import twitter from "../images/twitter.png";
import DiscordIcon from "../images/discord-icon.png";
import walletIcon from "./sharedComponents/walletIcon.svg";
import logoutIcon from "./sharedComponents/logoutIcon.svg";
import Pro from "../images/profile.svg";
import vLogo from "../images/v.svg";
import { WalletOutlined } from "@ant-design/icons"; // Import Ant Design Icons
import "./header.css";

function Header(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  // read data from redux
  const { venomInstance, venomProvider, standaloneProvider, walletError } = useSelector(state => state.venom);
  const { address, discordRole, discordUserName, errorMsg } = useSelector(state => state.user);
  const [userBalance, setUserBalance] = useState("0");

  // initialize venom Connect on loading component
  const init = async () => {
    const _venomConnect = await initVenomConnect();
    dispatch(setVenomInstance(_venomConnect));
  };

  const initStandalone = async () => {
    const standalone = await venomInstance?.getStandalone();
    dispatch(setStandaloneProvider(standalone));
  };

  // This method allows us to gen a wallet address from inpage provider
  const getAddress = async provider => {
    const providerState = await provider?.getProviderState?.();
    return providerState?.permissions.accountInteraction?.address.toString();
  };

  // When our provider is ready, we need to get address and balance from.
  const onProviderReady = async provider => {
    const venomWalletAddress = provider ? await getAddress(provider) : undefined;
    if (venomWalletAddress && venomWalletAddress != undefined) {
      dispatch(login(venomWalletAddress || null));
      dispatch(GetUser(venomWalletAddress));
    }
  };

  const onConnect = async provider => {
    dispatch(setVenomProvider(provider));
    await onProviderReady(provider);
  };

  // Any interaction with venom-wallet (address fetching is included) needs to be authentificated
  const checkAuth = async _venomConnect => {
    const auth = await _venomConnect?.checkAuth();
    if (auth) await getAddress(_venomConnect);
  };

  // Handling click of login button. We need to call connect method of out VenomConnect instance, this action will call other connect handlers
  const onLogin = async () => {
    if (!venomInstance) return;
    await venomInstance.connect();
  };

  const onLogout = async () => {
    venomProvider?.disconnect();
    // remove address from redux
    dispatch(logout());
  };

  useEffect(() => {
    // connect event handler
    const off = venomInstance?.on("connect", onConnect);
    if (venomInstance) {
      initStandalone();
      checkAuth(venomInstance);
    }
    // just an empty callback, cuz we don't need it
    return () => {
      off?.();
    };
  }, [venomInstance]);

  const getBalanceFunc = async () => {
    try {
      const balance = await venomProvider?.getBalance(address);
      const formattedBalance = (balance / 1000000000).toFixed(2).toString();
      setUserBalance(formattedBalance);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (venomProvider && address) {
      getBalanceFunc();
    }
  }, [venomProvider, address]);

  useEffect(() => {
    init();
  }, []);
  return (
    <div className="header-div">
      <div className="header-left">
        <Link to="/">
          <img src={HeaderIcon} alt="icon" width={"75px"} />
        </Link>
      </div>
      <div className="headerLInks">
        {/* <Link to="/officialmint" className={location.pathname === "/officialmint" ? "active-link" : ""}>
          Mint
        </Link> */}
        {/* <Link to="/submit" className={location.pathname === "/submit" ? "active-link" : ""}>
          Wallet Submission
        </Link> */}
        <a target="_blank" rel="noreferrer" href={`https://www.venomsea.io/`}>
          Venomsea.io
        </a>
        <a target="_blank" rel="noreferrer" href={`https://docs.venompunks.com/`}>
          Whitepaper
        </a>
      </div>
      <div className="headerIcons">
        <div className="right-icon">
          <a href="https://twitter.com/venom_punks" target="_blank">
            <img src={twitter} alt="icon" />
          </a>
        </div>

        <div className="right-icon">
          <a href="https://discord.gg/venompunks" target="_blank">
            <img src={DiscordIcon} alt="icon" />
          </a>
        </div>
      </div>
      <div className={`header-right ${true ? "show-menu" : ""}`}>
        {!address && (
          <button className="header-btn" onClick={onLogin}>
            Connect
          </button>
        )}
        {/* {address && (
          <div style={{ color: "white", fontSize: "15px" }}>{`${address.substring(0, 5)}....${address.substring(
            address.length - 4,
            address.length,
          )}`}</div>
        )} */}
        {/* {address && <button onClick={onLogout}>Logout</button>} */}
        {address && (
          <div className="logout">
            <div className="logout-address">
              <div>
                <WalletOutlined style={{ marginRight: "8px", color: "#36c59c" }} />
                <span className="hedarUserDetail">
                  {" "}
                  {`${address.substring(0, 5)}....${address.substring(address.length - 4, address.length)}`}
                </span>
              </div>
              <div style={{ textAlign: "end" }}>
                <img src={vLogo} alt="V" style={{ marginRight: "8px", width: "11px" }} />
                {/* <vLogo style={{ marginRight: "8px" ,color: '#36c59c'}} /> */}
                <span className="hedarUserDetail"> {userBalance}</span>
              </div>
            </div>

            {/* <div className="large-only">
              <WalletSvg />
            </div> */}
            <div className="large-only">
              <img src={walletIcon} />
            </div>
            <div className="large-only" style={{ marginRight: "1em" }}>
              {/* <ProfileSvg2 /> */}
              <img src={Pro} />
              {discordRole && discordRole.length > 0 && (
                <div className="tag">
                  <span style={{ fontSize: "16px" }}>{discordRole ? discordRole : ""}</span>
                </div>
              )}
            </div>

            <div onClick={onLogout} className="large-only">
              <img src={logoutIcon} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
