import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import Slash from "./pages/slash";
import SlashMint from "./pages/slashMint";
import WalletSubmission from "./pages/walletSubmission";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import store from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import HolderVerify from "./components/holderVerify/holder";

const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
    errorElement: <div>Oops something went wrong refresh</div>,
    children: [
      {
        path: "*",
        element: <Slash />,
      },
      // {
      //   path: "officialmint",
      //   element: <SlashMint />,
      // },
      {
        path: "verify-nft",
        element: <HolderVerify />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
  // </React.StrictMode>,
);
