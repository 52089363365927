import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
// import ReactGA from "react-ga";

import Header from "./components/header";
import Footer from "./components/footer";

import "./App.css";

export default function App() {
  // const location = useLocation();
  // useEffect(() => {
  //   ReactGA.initialize("G-X0S501JDC1");
  //   ReactGA.pageview(location.pathname + location.search);
  // }, []);
  return (
    <div className="body">
      <Header />
      <div id="detail">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
