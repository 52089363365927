import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../service";

export const GetUser = createAsyncThunk("users/getUser", async (walletAddress, { rejectWithValue }) => {
  try {
    const res = await API.get(`/users/${walletAddress}`);
    console.log(res, "response");
    if (res.status === 200) {
      return res.data.user;
    } else {
      return rejectWithValue("User Not Found");
    }
  } catch (err) {
    console.error(err);
    return rejectWithValue("Error in walletConnection");
  }
});

export const SubmitWallet = createAsyncThunk("users/submitWallet", async (payload, { rejectWithValue }) => {
  try {
    const res = await API.post(`/submit`, payload);
    console.log(res, "response");
    if (res.status === 200) {
      return res.data.user;
    } else {
      return rejectWithValue("User Not Found");
    }
  } catch (err) {
    console.error(err);
    return rejectWithValue("Error in walletConnection");
  }
});

export const GetUserRole = createAsyncThunk("users/getRole", async (walletAddress, { rejectWithValue }) => {
  try {
    const res = await API.get(`/userRole/${walletAddress}`);
    if (res.status === 200) {
      return res.data.role;
    } else {
      return rejectWithValue("Wallet not found!");
    }
  } catch (err) {
    return rejectWithValue("Invalid Wallet Address");
  }
});

export const dAppUser = createSlice({
  name: "dAppUser",
  initialState: {
    address: null,
    discordRole: null,
    discordUserName: null,
    errorMsg: null,
    // userRole state
    loading: false,
    userRole: null,
    error: "",
    success: false,
    roleLoader: false,
  },
  reducers: {
    login: (state, action) => {
      state.address = action.payload;
      state.errorMsg = null;
      state.discordRole = null;
      state.discordUserName = null;
    },
    logout: (state, action) => {
      state.address = null;
      state.errorMsg = null;
    },
    submitWalletRed: (state, { payload }) => {
      state.discordRole = payload.discordRole;
      state.discordUserName = payload.discordUserName;
    },
    reSetRole: state => {
      state.loading = false;
      state.userRole = "";
      state.error = "";
      state.success = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(GetUser.fulfilled, (state, { payload }) => {
        state.discordRole = payload.discordRole;
        state.discordUserName = payload.discordUserName;
      })
      .addCase(SubmitWallet.fulfilled, (state, { payload }) => {
        console.log(payload, "payload");
        state.discordRole = payload.discordRole;
        state.discordUserName = payload.discordUserName;
      })
      .addCase(GetUser.pending, state => {
        console.log("pending");
      })
      .addCase(GetUser.rejected, state => {
        console.log("rejected");
      })
      .addCase(GetUserRole.pending, state => {
        state.loading = true;
        state.success = false;
        state.error = "";
        state.userRole = null;
      })
      .addCase(GetUserRole.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.error = "";
        state.userRole = payload;
      })
      .addCase(GetUserRole.rejected, (state, payload) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
        state.userRole = null;
      });
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, submitWalletRed, reSetRole } = dAppUser.actions;

export default dAppUser.reducer;
