import React, { useState } from "react";
import background from ".././images/traits/Background.png";
import beard from ".././images/traits/beard.png";
import body from ".././images/traits/body.png";
import ear from ".././images/traits/ear.png";
import glasses from ".././images/traits/glasses.png";
import headwear from ".././images/traits/hat.png";
import mouth from ".././images/traits/mouth.png";
import mouthPiece from ".././images/traits/Mouthpiece.png";
import nose from ".././images/traits/nose.png";
import chain from ".././images/traits/chain.png";
import eyes from ".././images/traits/eyes.png";
import "./traits.css";

const Traits = () => {
  const [hovered, setHovered] = useState(null);
  const [otherElements, setOtherElements] = useState(1);

  const hoverProperty = id => {
    setHovered(id);
    setOtherElements(0);
  };

  const reset = id => {
    setHovered(null);
    setOtherElements(1);
  };
  console.log(otherElements, "other elements");
  console.log(hovered, "id");

  return (
    <div className="traitsParent">
      <h1>Traits</h1>
      <div className="traitsBody">
        <div className="firstListDiv">
          <li
            id="body"
            onMouseEnter={() => hoverProperty("body")}
            onMouseOut={() => {
              reset("body");
            }}
          >
            Body
          </li>
          <li
            id="head"
            onMouseEnter={() => hoverProperty("head")}
            onMouseOut={() => {
              reset("head");
            }}
          >
            Head
          </li>
          <li
            id="ears"
            onMouseEnter={() => hoverProperty("ears")}
            onMouseOut={() => {
              reset("ears");
            }}
          >
            Ears
          </li>

          <li
            id="facialhair"
            onMouseEnter={() => hoverProperty("facialhair")}
            onMouseOut={() => {
              reset("facialhair");
            }}
          >
            Facial Hair
          </li>
          <li
            id="neckaccessory"
            onMouseEnter={() => hoverProperty("neckaccessory")}
            onMouseOut={() => {
              reset("neckaccessory");
            }}
          >
            Neck Accessory
          </li>
        </div>
        <div className="imagesContainer">
          <img
            src={background}
            alt="trait"
            // style={{
            //   opacity: hovered === "background" ? 1 : `${otherElements}`,
            // }}
          />
          <img
            src={body}
            alt="trait"
            style={{
              opacity: hovered === "body" ? 1 : `${otherElements}`,
            }}
          />
          <img
            src={beard}
            alt="trait"
            style={{
              opacity: hovered === "facialhair" ? 1 : `${otherElements}`,
            }}
          />
          {/* properties */}
          <img
            src={eyes}
            alt="trait"
            style={{
              opacity: hovered === "eyes" ? 1 : `${otherElements}`,
            }}
          />
          <img
            src={glasses}
            alt="trait"
            style={{
              opacity: hovered === "eyewear" ? 1 : `${otherElements}`,
            }}
          />
          <img
            src={ear}
            alt="trait"
            style={{
              opacity: hovered === "ears" ? 1 : `${otherElements}`,
            }}
          />

          <img
            src={headwear}
            alt="trait"
            style={{
              opacity: hovered === "head" ? 1 : `${otherElements}`,
            }}
          />
          <img
            src={mouth}
            alt="trait"
            style={{
              opacity: hovered === "mouth" ? 1 : `${otherElements}`,
            }}
          />
          {/* dont forget to change the properties */}

          <img
            src={mouthPiece}
            alt="trait"
            style={{
              opacity: hovered === "mouthproperty" ? 1 : `${otherElements}`,
            }}
          />
          <img
            src={chain}
            alt="trait"
            style={{
              opacity: hovered === "neckaccessory" ? 1 : `${otherElements}`,
            }}
          />

          <img
            src={nose}
            alt="trait"
            style={{
              opacity: hovered === "nose" ? 1 : `${otherElements}`,
            }}
          />
        </div>
        <div className="listTwoDiv">
          <li
            id="eyewear"
            onMouseEnter={() => hoverProperty("eyewear")}
            onMouseOut={() => {
              reset("eyewear");
            }}
          >
            Eye Wear
          </li>
          <li
            id="nose"
            onMouseEnter={() => hoverProperty("nose")}
            onMouseOut={() => {
              reset("nose");
            }}
          >
            Nose
          </li>
          <li
            id="eyes"
            onMouseEnter={() => hoverProperty("eyes")}
            onMouseOut={() => {
              reset("eyes");
            }}
          >
            Eyes
          </li>

          <li
            id="mouth"
            onMouseEnter={() => hoverProperty("mouth")}
            onMouseOut={() => {
              reset("mouth");
            }}
          >
            Mouth
          </li>
          <li
            id="mouthproperty"
            onMouseEnter={() => hoverProperty("mouthproperty")}
            onMouseOut={() => {
              reset("mouthproperty");
            }}
          >
            Mouth Property
          </li>
        </div>
      </div>
    </div>
  );
};

export default Traits;
