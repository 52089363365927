import { VenomConnect } from "venom-connect";
import { ProviderRpcClient } from "everscale-inpage-provider";
import { EverscaleStandaloneClient } from "everscale-standalone-client";

export const initVenomConnect = async () => {
  return new VenomConnect({
    theme: "dark",
    checkNetworkId: 1,
    providersOptions: {
      venomwallet: {
        walletWaysToConnect: [
          {
            package: ProviderRpcClient,

            packageOptions: {
              fallback: VenomConnect.getPromise("venomwallet", "extension") || (() => Promise.reject()),
              forceUseFallback: true,
            },
            packageOptionsStandalone: {
              fallback: () =>
                EverscaleStandaloneClient.create({
                  connection: {
                    id: 1,
                    group: "venom_mainnet",
                    type: "jrpc",
                    data: {
                      endpoint: "https://jrpc.venom.foundation/rpc",
                      // endpoint: 'https://jrpc-testnet.venom.foundation/rpc',
                      // endpoint: 'https://gql-devnet.venom.network/graphql',
                      // endpoint: "https://venom-mainnet.tvmlabs.dev/graphql",
                    },
                  },
                }),
              forceUseFallback: true,
            },

            id: "extension",
            type: "extension",
          },
        ],
        defaultWalletWaysToConnect: ["mobile", "ios", "android"],
      },
    },
  });
};
