import React, { useState, useEffect } from "react";
import axios from "axios";
import { Address } from "everscale-inpage-provider";
import verifyIcon from "../../images/verify.png";
import "./holder.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { API } from "../../service";

function HolderVerify(props) {
  const { venomInstance, venomProvider } = useSelector(state => state.venom);
  const { address } = useSelector(state => state.user);
  const [loader, setLoader] = useState(false);
  const [discordVerify, setDiscordVerify] = useState(false);
  const [discordInfo, setDiscordInfo] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  console.log(discordInfo, "discordInfo");

  // use useEffect hook to check if we are being redirected to this page after verifying from discord
  useEffect(() => {
    const params = new URLSearchParams(window.location.hash.substr(1));
    const accessToken = params.get("access_token");
    console.log(accessToken);
    if (accessToken) {
      console.log("getting roles");
      // we are being redirected so, handle discord.
      // get roles information for connected user/
      handleGetRoles(accessToken).then(guilds => {
        if (!guilds) {
          // user is not in our server.
          return;
        }
        console.log(guilds, "guildddd");
        // set setDiscordInfo
        // dispatch(
        //   submitWalletRed({
        //     discordUserName: guilds.user.username,
        //     discordRole: getRoleString(guilds.roles),
        //   }),
        // );
        setDiscordInfo({
          username: guilds.user.username,
          userId: guilds.user.id,
        });

        setDiscordVerify(true);
      });
    }
  }, []);

  const showSuccessToast = (text = "Successfully claimed role!!") => {
    toast.success(text, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  const showFailedToast = (text = "Failed to claim role!!") => {
    toast.error(text, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  const callClaimRole = async payload => {
    try {
      setLoader(true);
      const response = await API.post("/manage-roles", payload);
      if (response.status === 200) {
        //show toast here
        setSuccess(true);
        setLoader(false);
        showSuccessToast();
        return response.data;
      }
    } catch (error) {
      setError(`You don't owned any nft!!`);
      showFailedToast();
      console.error("Error occurred while calling API:", error);
      setLoader(false);
      //show toast here
      // Handle error appropriately, you might want to throw it or return false indicating failure
      return false;
    }
  };

  const submitWallet = async () => {
    console.log("submitting wallet");
    setLoader(true);
    console.log(new Address(address));
    const providerState = await venomProvider?.getProviderState?.();
    console.log(providerState?.permissions);
    const publicKey = providerState?.permissions.accountInteraction?.publicKey;
    console.log(publicKey);
    try {
      const base64 = btoa(
        JSON.stringify({
          walletAddress: address,
          userId: discordInfo.userId,
        }),
      );
      console.log(base64);
      const sign = await venomProvider.signDataRaw({ data: base64, publicKey, withSignatureId: false });
      console.log(sign);
      callClaimRole({ publicKey, base64, signature: sign.signature });
      // dispatch(ClaimRole({ publicKey, base64, signature: sign.signature }));
    } catch (errr) {
      console.log(errr);
      setLoader(false);
    }
    // venomProvider.sign('ddd');
    // console.log(standaloneProvider)
  };
  //https://venompunks.com
  //http://127.0.0.1:3001
  const handleDiscordConnect = () => {
    // window.location.href = `https://discord.com/oauth2/authorize?client_id=1216820185282117743&response_type=code&redirect_uri=https%3A%2F%2Ffc8k2gzm-300.inc1.devtunnels.ms%2Fverify-nft&scope=identify+guilds+guilds.members.read`;
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=1216848625959960576&redirect_uri=https://venompunks.com/verify-nft&response_type=token&scope=identify%20guilds%20guilds.members.read`;
    // window.location.href = `https://discord.com/api/oauth2/authorize?client_id=1161620227444965387&redirect_uri=http://127.0.0.1:3001/submit&response_type=token&scope=identify%20guilds%20guilds.members.read`;
  };

  const handleGetRoles = async accessToken => {
    try {
      const response = await axios.get("https://discord.com/api/v10/users/@me/guilds/1059909343300046969/member", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const guilds = response.data;
      return guilds;
    } catch (error) {
      console.error("Error fetching guilds:", error);
      return null;
    }
  };
  const connectWllet = async () => {
    if (!venomInstance) return;
    await venomInstance.connect();
  };

  return (
    <>
      <div className="holdersContainer">
        <div className="verification-heading">
          <h3>Holder Verification</h3>
        </div>
        <div className="holderParent">
          <div className="holderStep1Container">
            <h3>Step 01 Wallet Connection</h3>
            <p>
              We kindly request you to establish a connection with your wallet to provide us with your wallet address.
            </p>
            {!address && <button onClick={connectWllet}>Connect Wallet</button>}
            {address && (
              <div className="verifyStepOne">
                <img src={verifyIcon} alt="verify" />
                <p>{`${address.substring(0, 5)}....${address.substring(address.length - 4, address.length)}`}</p>
              </div>
            )}
          </div>
          <hr />
          <div className={`${!address ? "stepTwoDisconnectContainer" : "stepTwoContainer"}`}>
            <h3>Step 02 Discord Integration</h3>
            <p>Please initiate the connection of your Discord account to get the holder role.</p>
            {discordVerify ? (
              <div className="verifyStepTwoContainer">
                <img src={verifyIcon} alt="verify" />
                <p>Congratulations! successfully connected to discord </p>
              </div>
            ) : (
              <button onClick={handleDiscordConnect} disabled={!address}>
                Verify Discord
              </button>
            )}
          </div>
          <hr />
          <div
            className={`${!address || !discordInfo.userId ? " stepThreeDisconnectContainer" : "stepThreeContainer"}`}
          >
            <h3>Step 03 Sign Message</h3>
            <p>
              To complete the final submission, we kindly request you to log in with your wallet. Please be informed
              that this process does not entail any transaction execution.
            </p>

            {success ? (
              <div className="verifyStepTwoContainer">
                <img src={verifyIcon} alt="verify" />
                <p>You have successfully claim your Role check discord!! </p>
              </div>
            ) : (
              <div>
                {discordVerify && (
                  <>
                    <p>{`Discord user: ${discordInfo.username}`}</p>
                    {/* <p>{`Discord role: ${discordInfo.role}`}</p> */}
                  </>
                )}
                <button onClick={submitWallet} disabled={loader}>{`Claim Role${loader ? "..." : ""}`}</button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default HolderVerify;
