import { configureStore } from "@reduxjs/toolkit";
import venomReducer from "./reducers/venom";
import userReducer from "./reducers/user";

export default configureStore({
  reducer: {
    user: userReducer,
    venom: venomReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});
